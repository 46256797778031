import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { useCallback, useEffect, useRef, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import {
  useAuthState,
  useAuthDispatch,
} from "../../../../MainComponents/GlobalContext";
import Loader from "../../../../MainComponents/Loader";
import { CSVLink } from "react-csv";
import RequestsCard from "./RequestsCard.jsx";
import axios from "axios";
import { set } from "react-hook-form";
import toast from "react-hot-toast";
import { Modal } from "antd";

function Requests() {
  const AuthState = useAuthState();
  const AuthDispatch = useAuthDispatch();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [submitAccept, setSubmitAccept] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [submitSearch, setSubmitSearch] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [reFetch, setReFetch] = useState(false);
  const [academiclevel, setAcademiclevel] = useState(1);
  const [academicType, setAcademicType] = useState("scientific");
  const [left, setLeft] = useState("left-0");
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchedInput, setSearchedInput] = useState("");
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const observerRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [lastPage, setLastPage] = useState(null);
  const [loadingCards, setLoadingCards] = useState({});
  const [per_page, setPer_Page] = useState(25);
  const [search, setSearch] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //!--------- Fetch Initial Data -------
  const fetchInitialData = useCallback(async () => {
    setLoading(true);
    setFilteredRequests([]);
    setPage(1);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ALL_REQUESTS_API}/${academiclevel}?page=1&per_page=${per_page}`
      );
      setFilteredRequests(response.data.data);
      setHasMore(response.data.pagination.last_page > 1);
      setLastPage(response.data.pagination.last_page);
    } catch (error) {
      console.error("Error fetching initial data:", error);
    } finally {
      setLoading(false);
    }
  }, [academiclevel]);

  useEffect(() => {
    fetchInitialData();
  }, [academiclevel, fetchInitialData]);

  //!--------- Delete Request -------
  const [deleteRequestSuccess, deleteRequestErrors] = useAxios(
    process.env.REACT_APP_DELETE_REQUEST_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  //!--------- Search Request -------
  const [SearchRequestSuccess, SearchRequestErrors, LoadingRequestSuccess] =
    useAxios(
      process.env.REACT_APP_SEARCH_REQUEST_API,
      "POST",
      submitSearch.flag,
      submitSearch.dependency,
      submitSearch.data,
      true
    );

  //!--------- Accept Request -------
  const [acceptRequestSuccess, acceptRequestErrors] = useAxios(
    process.env.REACT_APP_ACCEPT_REQUEST_API,
    "POST",
    submitAccept.flag,
    submitAccept.dependency,
    submitAccept.data,
    true
  );

  /*   useEffect(() => {
    if (deleteRequestSuccess || acceptRequestSuccess) {
      fetchInitialData();
      setReFetch(!reFetch);
      AuthDispatch({
        type: "setRefetchIfAcceptOrDeleteRequest",
      });
    }
  }, [deleteRequestSuccess, acceptRequestSuccess]); */

  function handleDeleteReq(id) {
    setLoadingCards((prev) => ({ ...prev, [id]: true }));
    setSubmitDelete({
      flag: "deleteRequest",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }

  function handleAcceptReq(id) {
    setLoadingCards((prev) => ({ ...prev, [id]: true }));
    setSubmitAccept({
      flag: "acceptRequest",
      dependency: !submitAccept.dependency,
      data: { id: id },
    });
  }
  const handleChangeacAdemicLevel = (position, acadimcNum) => {
    setLeft(position);
    setAcademiclevel(acadimcNum);
    setPage(1);
    setHasMore(true);
    setFilteredRequests([]);
  };

  const handleSearche = (e) => {
    setIsModalOpen(true);
    setSubmitSearch({
      flag: "searchRequest",
      dependency: !submitSearch.dependency,
      data: { stage: academiclevel, mobile: searchedInput },
    });
    console.log(searchedInput);
    console.log(submitSearch);
  };
  console.log(SearchRequestSuccess);

  //!--------- Fetch More Data -------
  const fetchMoreData = useCallback(async () => {
    if (!hasMore || isFetching || (lastPage && page > lastPage)) return;

    setIsFetching(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ALL_REQUESTS_API}/${academiclevel}?page=${
          page + 1
        }&per_page=${per_page}`
      );

      setFilteredRequests((prev) => [...prev, ...response.data?.data]);
      setHasMore(
        response.data.pagination.current_page <
          response.data.pagination.last_page
      );
      setLastPage(response.data.pagination.last_page);
      setPage((prev) => prev + 1);
    } catch (error) {
      console.error("Error fetching more data:", error);
    } finally {
      setIsFetching(false);
    }
  }, [academiclevel, hasMore, isFetching, lastPage, page]);

  useEffect(() => {
    if (!hasMore) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isFetching) {
          fetchMoreData();
        }
      },
      { threshold: 0.5 }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [hasMore, fetchMoreData, isFetching]);

  const data =
    academiclevel === 2 || academiclevel === 3
      ? filteredRequests.filter((data) => data.section === academicType)
      : filteredRequests;

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Requests | Mr.Mohamed Salama"></HelmetTags>

      <div className="w-full flex justify-between items-center gap-5 mb-7">
        <h2 className="text-start text-2xl font-bold">Pending Students</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("request_accept")
          )) && (
          <button className="rounded-lg border-2 border-delete bg-delete px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-delete active:scale-90">
            <CSVLink data={data} filename="Requested Students">
              Export
            </CSVLink>{" "}
            <FontAwesomeIcon className="ml-2" icon={faDownload} />
          </button>
        )}
      </div>

      {/* Academic Level Selector */}
      <div
        className={`filter relative flex h-10 min-h-[40px] w-72 justify-start mb-10`}
      >
        <button
          onClick={() => handleChangeacAdemicLevel("left-o", 1)}
          className="z-10 h-full w-1/3 text-base font-semibold"
        >
          Stage 1
        </button>
        <button
          onClick={() => handleChangeacAdemicLevel("left-1/3", 2)}
          className="z-10 h-full w-1/3 text-base font-semibold"
        >
          Stage 2
        </button>
        <button
          onClick={() => handleChangeacAdemicLevel("left-2/3", 3)}
          className="z-10 h-full w-1/3 text-base font-semibold"
        >
          Stage 3
        </button>
        <div
          className={`std-selector absolute top-0 h-full w-1/3 ${left} rounded-md bg-green duration-300`}
        ></div>
      </div>

      {/* Academic Type Selector */}
      {academiclevel === 2 || academiclevel === 3 ? (
        <div
          className={`filter relative flex h-10 min-h-[40px] w-72 justify-center mb-10`}
        >
          <button
            onClick={() => setAcademicType("scientific")}
            className={`z-10 h-full w-1/3 text-base font-semibold ${
              academicType === "scientific" && "bg-green"
            }`}
          >
            Scientific
          </button>
          <button
            onClick={() => setAcademicType("literary")}
            className={`z-10 h-full w-1/3 text-base font-semibold ${
              academicType === "literary" && "bg-green"
            }`}
          >
            Literary
          </button>
        </div>
      ) : null}

      {/* Search Input */}
      <div className="search-wrapper w-1/2 md:w-full mb-8">
        <input
          type="search"
          className="signin-inputs px-4 placeholder:!text-base"
          placeholder="Search..."
          onChange={(e) => setSearchedInput(e.target.value)}
          value={searchedInput}
        />
        <button
          onClick={handleSearche}
          disabled={!searchedInput || LoadingRequestSuccess}
          className={` border border-secondary group mt-4 rounded-md pt-2 pb-1 px-[6px] duration-300 hover:bg-secondary hover:text-bg   active:scale-90 `}
        >
          Submit
        </button>
      </div>

      <Modal
        open={isModalOpen}
        footer={[
          <button
            onClick={() => setIsModalOpen(false)}
            className={` border border-secondary group mt-4 rounded-md pt-2 pb-1 px-[6px] duration-300 hover:bg-secondary hover:text-bg   active:scale-90 `}
          >
            Cancel
          </button>,
        ]}
        onCancel={() => setIsModalOpen(false)}
      >
        {LoadingRequestSuccess ? (
          <p>Loading....</p>
        ) : SearchRequestErrors ? (
          <p>Error Occured</p>
        ) : (
          SearchRequestSuccess && (
            <RequestsCard
              std={SearchRequestSuccess?.data}
              handleAcceptReq={handleAcceptReq}
              handleDeleteReq={handleDeleteReq}
              isLoading={loadingCards[SearchRequestSuccess?.data?.key] || false}
              Modal={true}
            />
          )
        )}
      </Modal>

      {/* Requests List */}
      <div className="students flex w-full flex-col items-center gap-10">
        {loading ? (
          <Loader />
        ) : filteredRequests.length === 0 ? (
          <p className="w-full text-center">There are no new requests</p>
        ) : (
          <>
            {data.map((std, i) => (
              <RequestsCard
                std={std}
                key={i}
                handleAcceptReq={handleAcceptReq}
                handleDeleteReq={handleDeleteReq}
                isLoading={loadingCards[std.key] || false}
              />
            ))}
            <div ref={observerRef} style={{ height: 30 }} />
          </>
        )}
      </div>
    </section>
  );
}

export default Requests;
