import { useEffect, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import StudentNumberCheck from "./component/StudentNumberCheck";
import CourseSelect from "./component/CourseSelect";
import SessionSelect from "./component/SessionSelect";
import ExecptionType from "./component/ExecptionType";
import SessionForm from "./component/SessionForm";
import QuizAndHomeworkForm from "./component/QuizAndHomeworkForm";
import VideoForm from "./component/VideoForm";
import CoursesForm from "./component/CoursesForm";
import PurchasesForm from "./component/PurchasesForm";
import UnlockCourse from "./component/UnlockCourseForm";
import ViewsForm from "./component/ViewsForm";
import InvisibleCourseForm from "./component/InvisibleCourseForm";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { setRef } from "yet-another-react-lightbox";

const Exception = () => {
  const [type, setType] = useState("courses");
  const [courseId, setCourseId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [courseSelectValues, setCourseSelectValues] = useState([]);
  const [sessionSelectValues, setSessionSelectValues] = useState([]);
  const [allViewsVideos, setAllViewsVideos] = useState([]);
  const [values, setValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [dependency, setDependency] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  /*   const [
    GetMobileInvisibleCoursesData,
    GetMobivleInvisibleCoursesErrors,
    GetMobivleInvisibleCoursesLoading,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_GET_INVISBLE_COURSES_API,
    "GET",
    formFlag,
    dependency,
    values
  ); */

  /*  useEffect(() => {
    setFormFlag(courseId);
    setDependency(true);
    setValues({
      id: courseId,
      mobile: mobileNumber,
    });
  }, [mobileNumber, courseId]); */
  console.log("session", sessionSelectValues);
  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Exception Student | Mr. Mohamed Salama" />
      <h2 className="text-start text-2xl font-bold">Exception Student</h2>

      <ExecptionType
        setType={setType}
        setMobileNumber={setMobileNumber}
        setCourseId={setCourseId}
      />

      <div className="my-8 mx-auto w-3/4 md:w-full flex flex-col items-start justify-center gap-10 rounded-3xl bg-white p-16 shadow-lg shadow-red-200/50">
        <StudentNumberCheck
          setCourseSelectValues={setCourseSelectValues}
          setMobileNumber={setMobileNumber}
          type={type}
        />

        {courseSelectValues.length > 0 && (
          <CourseSelect
            values={courseSelectValues}
            setSessionSelectValues={setSessionSelectValues}
            setCourseId={setCourseId}
            mobileNumber={mobileNumber}
            type={type}
          />
        )}

        {sessionSelectValues.length > 0 &&
          courseSelectValues.length > 0 &&
          type !== "courses" &&
          type !== "purchases" &&
          type !== "unlockCourse" && (
            <SessionSelect
              values={sessionSelectValues}
              courseId={courseId}
              setSessionId={setSessionId}
              setAllViewsVideos={setAllViewsVideos}
              setReFetch={setReFetch}
              reFetch={reFetch}
            />
          )}

        {type === "courses" &&
          courseSelectValues.length > 0 &&
          sessionSelectValues.length > 0 && (
            <CoursesForm courseId={courseId} mobileNumber={mobileNumber} />
          )}

        {type === "session" && courseSelectValues.length > 0 && sessionId && (
          <SessionForm
            courseId={courseId}
            mobileNumber={mobileNumber}
            sessionId={sessionId}
          />
        )}

        {type === "quiz" && courseSelectValues.length > 0 && sessionId && (
          <QuizAndHomeworkForm />
        )}

        {type === "homework" && courseSelectValues.length > 0 && sessionId && (
          <QuizAndHomeworkForm homework />
        )}

        {type === "video" && courseSelectValues.length > 0 && sessionId && (
          <VideoForm />
        )}

        {type === "purchases" && courseSelectValues.length > 0 && courseId && (
          <PurchasesForm courseId={courseId} mobileNumber={mobileNumber} />
        )}

        {type === "unlockCourse" &&
          courseSelectValues.length > 0 &&
          courseId && (
            <UnlockCourse courseId={courseId} mobileNumber={mobileNumber} />
          )}

        {type === "views" && courseSelectValues.length > 0 && sessionId && (
          <ViewsForm
            courseId={courseId}
            mobileNumber={mobileNumber}
            values={allViewsVideos}
            setReFetch={setReFetch}
            reFetch={reFetch}
          />
        )}

        {type === "invisiblecourses" &&
          courseSelectValues.length > 0 &&
          courseId && (
            <InvisibleCourseForm
              mobileNumber={mobileNumber}
              course_id={courseId}
            />
          )}
      </div>

      {/*    <div>
        {GetMobivleInvisibleCoursesErrors ? (
          <div>{GetMobivleInvisibleCoursesErrors.message}</div>
        ) : (
          <div>
            {GetMobileInvisibleCoursesData?.data?.map((i, idx) => (
              <div key={idx}>{i}</div>
            ))}
          </div>
        )}
      </div> */}
    </section>
  );
};

export default Exception;
