import { DatePicker } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Spin } from "antd";
import toast from "react-hot-toast";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf"; // Import jsPDF library
const { RangePicker } = DatePicker;

export default function WrongStudents() {
  const [date, setDate] = useState([]);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    depdency: false,
    data: {},
  });

  const [successData, errorData, loadingSuccess] = useAxios(
    process.env.REACT_APP_ANALYTICS_TOP_WRONG_STUDENTS_API,
    "POST",
    submitAdd.flag,
    submitAdd.depdency,
    submitAdd.data,
    true
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm();

  const handleDateChange = (dates, dateString) => {
    if (dates) {
      const formattedDates = dates.map((date) => date.format("YYYY-MM-DD")); // Format to "YYYY-MM-DD"
      setDate(formattedDates);
      setValue("dateRange", formattedDates); // Set for validation
    }
  };

  const onSubmit = (data) => {
    if (!date.length) {
      toast.error("Please select a valid date range.");
      return;
    }

    const [from, to] = date;

    setSubmitAdd({
      flag: "Change Table",
      depdency: !submitAdd.depdency,
      data: {
        from,
        to,
        stage: data.stage,
        limit: data.limit,
      },
    });
  };

  // Function to export data as PDFconst exportPDF = () => {
  const exportPDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("Wrong Students Report", 20, 20);

    let yOffset = 30; // Initial vertical offset

    successData?.data.forEach((student) => {
      // Check if the content for the student will fit on the current page
      if (yOffset + 40 > doc.internal.pageSize.height) {
        doc.addPage(); // Add a new page if content exceeds the page
        yOffset = 20; // Reset yOffset for new page
      }

      // Add student details to PDF
      doc.setFontSize(12);
      doc.text(`Student Name: ${student.student_name}`, 20, yOffset);
      yOffset += 10;

      doc.text(`Wrong Count: ${student.wrong_count}`, 20, yOffset);
      yOffset += 10;

      doc.text(`Mobile: ${student.mobile}`, 20, yOffset);
      yOffset += 10;

      doc.text(`Parent Mobile: ${student.parent_mobile}`, 20, yOffset);
      yOffset += 30; // Add 30px gap after each student's details
    });

    doc.save("wrong_students_report.pdf"); // Download the generated PDF
  };

  return (
    <div className="px-10 py-10 flex flex-col gap-5 min-h-screen">
      {/* Form Section */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-1/2 sm:w-full space-y-5 rounded-3xl border border-secondary shadow-2xl p-5"
      >
        {/* Date Range Picker */}
        <RangePicker
          className="signin-inputs"
          onChange={handleDateChange}
          getPopupContainer={(trigger) => trigger.parentNode}
        />
        {errors.dateRange && (
          <p className="text-red-500">{errors.dateRange.message}</p>
        )}

        {/* Stage Selector */}
        <select
          className="signin-inputs"
          {...register("stage", { required: "Stage selection is required" })}
        >
          <option value="">Select a stage</option>
          <option value="1">Stage 1</option>
          <option value="2">Stage 2</option>
          <option value="3">Stage 3</option>
        </select>
        {errors.stage && <p className="text-red-500">{errors.stage.message}</p>}

        {/* Limit Input */}
        <input
          type="number"
          min={1}
          max={1000}
          className="signin-inputs"
          placeholder="Limit of students"
          {...register("limit", {
            required: "Limit is required",
            min: { value: 1, message: "Minimum limit is 1" },
            max: { value: 1000, message: "Maximum limit is 1000" },
          })}
        />
        {errors.limit && <p className="text-red-500">{errors.limit.message}</p>}

        {/* Submit Button */}
        <button
          type="submit"
          className="submit"
          disabled={loadingSuccess || !isValid}
        >
          {loadingSuccess ? <Spin /> : "Submit"}
        </button>
      </form>

      {/* PDF Export Button */}
      {successData && successData.data.length > 0 && (
        <button
          onClick={exportPDF}
          className=" w-[200px] rounded-lg border-2 border-delete bg-delete px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-delete   active:scale-90 "
        >
          Download PDF
          <FontAwesomeIcon className="ml-2" icon={faDownload} />
        </button>
      )}
      {/* Student Cards */}
      <div className="flex gap-5 flex-wrap">
        {loadingSuccess
          ? null
          : successData &&
            successData.data.map((student, index) => (
              <div
                key={student.id}
                className="w-full max-w-[400px] p-4 rounded-2xl shadow-lg bg-white"
              >
                <div className="flex flex-col gap-3 items-center">
                  {/* Student Profile Picture */}
                  <img
                    src={student.profile_pic}
                    alt={student.student_name}
                    className="w-24 h-24 rounded-full"
                  />

                  {/* Student Name */}
                  <p className="text-xl font-bold">{student.student_name}</p>

                  {/* Wrong Count */}
                  <p className="text-red-500 text-lg">
                    Total Wrong: {student.wrong_count}
                  </p>

                  {/* Mobile */}
                  <p className="text-gray-700">Mobile: {student.mobile}</p>

                  {/* Parent Mobile */}
                  <p className="text-gray-700">
                    Parent Mobile: {student.parent_mobile}
                  </p>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}
